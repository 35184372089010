import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'
import { createNotification } from '@/lib/unnotificationsqueue'
import { unNumberFmt } from '@/lib/uncrudtable/mixins/filters'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import Layout from '@/apps/core/components/Layout'
import ForecastingByProductFilters from '@/apps/deimos/components/ForecastingByProductFilters/ForecastingByProductFilters.vue'
import HierarchiesPivotTableBase from '@/apps/core/components/HierarchiesPivotTableBase/HierarchiesPivotTableBase.vue'

@Component({
  components: {
    Layout,
    ForecastingByProductFilters,
    HierarchiesPivotTableBase
  },
  props: {
  },
  computed: {
    ...mapState('forecasting', { forecastData: 'forecastData', forecastLoading: 'forecastLoading' })
  }
})

class ForecastingByProduct extends Vue {
  sections = sections

  hideTable = true
  forecastList = []

  tableConfiguration = {
    columnFields: {},
    cellsEndpoint: `/forecast-lines/`,
    rowsEndpoints: [],
    columnsEndpoints: [],
    rowIdentifier: 'distributor_hierarchy_element',
    columnIdentifier: 'time_hierarchy_element',
    rowLabel: '',
    /**
     * To be called by the HPT-Data's orderedRows to determine whether a cell is editable
     * @param pivotTableData {Object} HPT-Data "this" instance
     * @param rowHierarchyElement {Object} Row hierarchy element that the cell belongs to
     * @param cell {Object} The cell instance
     * @returns {boolean}
     */
    isCellEditable: (pivotTableData, rowHierarchyElement, cell, column) => {
      return true
    },
    customCellFormatters: {
      'promotional_sales': {
        fmt: v => unNumberFmt(v, 0)
      },
      'base_sales': {
        fmt: v => unNumberFmt(v, 0)
      },
      'user_proposed_sales': {
        fmt: v => unNumberFmt(v, 0)
      },
      'total_sales': {
        fmt: v => unNumberFmt(v, 0)
      },
      'materialized.sales_var_ya': {
        fmt: v => unNumberFmt(v * 100, 1),
        suffix: '%'
      }
    }
  }

  async created () {}

  async mounted () {
    this.$watch('forecastData', await this.watchForecastDataSelected)
  }

  /**
   * Event watchers
   * **/
  async watchForecastDataSelected (data) {
    this.hideTable = true
    if (data !== undefined) {
      this.setEndpointsParams(data)
      await this.generatePivotTableColumnFields(data)
    }
    this.hideTable = false
  }

  /**
   * Sets the columnFields to tell the HPT-Data which columns are to be displayed
   */
  async generatePivotTableColumnFields (data) {
    let defaultColumnExternalIds = []
    await this.$store.dispatch('forecasting/getTimeHierarchyLevelByForecastId', data.forecast.id).then(res => {
      res.forEach(column => {
        defaultColumnExternalIds.push(column.external_id)
      })
    })
    defaultColumnExternalIds.forEach(columnId => {
      this.tableConfiguration.columnFields[columnId] = [
        { label: 'Promo', value: 'promotional_sales', editable: false, decimalCountValue: 0 },
        { label: 'KAMs', value: 'base_sales', editable: false, decimalCountValue: 0 },
        { label: 'DP', value: 'user_proposed_sales', editable: true, decimalCountValue: 0 },
        { label: 'Final', value: 'total_sales', editable: false, decimalCountValue: 0 },
        { label: '% Var AA', value: 'materialized.sales_var_ya', editable: false, decimalCountValue: 1 }
      ]
    })
  }

  setEndpointsParams (data) {
    this.tableConfiguration.rowsEndpoints = [{
      url: `/forecast-distributor-hierarchy-levels/forecastingdemandplanner__total/hierarchy-elements/?forecast_id=${data.forecast.id}&depth=1`,
      responseField: 'data'
    }]
    this.tableConfiguration.columnsEndpoints = [{
      url: `/forecast-time-hierarchy-levels/Mes/hierarchy-elements/?forecast_id=${data.forecast.id}`,
      responseField: 'data'
    }]
    this.tableConfiguration.cellsEndpoint = `/forecast-lines/?forecast_id=${data.forecast.id}&limit=10000&product_hierarchy_element=${data.sku.id}`
  }

  onSaveForecast (filters) {
    this.hideTable = false
  }

  async onSaveCell (fullInstance) {
    try {
      let cellBody = {}
      if (fullInstance.elementsChanged.length) {
        fullInstance.elementsChanged.forEach(changedField => {
          cellBody[changedField] = fullInstance[changedField]
        })
        let patchResponse = await apiClient.patch(`/forecast-lines/${fullInstance.id}/?updated_lines=true`, cellBody)
        await this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.savedNewValues'), RESPONSE_LEVEL.SUCCESS))
        this.doRefreshUpdatedCells(patchResponse)
      } else {
        await this.$store.dispatch('addNotification', createNotification('No data was modified', 'info'))
        this.$refs.hierarchiesPivotTableBaseComponent.undoUpdate(fullInstance.id)
      }
      this.$refs.hierarchiesPivotTableBaseComponent.isUpdateFinished()
    } catch (error) {
      let message = error.response && error.response.data && error.response.data.message
      let level = error.response && error.response.data && error.response.data.level
      await this.$store.dispatch('addNotification', createNotification(message || this.$t('dialogs.errorWhenSaving'), level || RESPONSE_LEVEL.ERROR))
      this.$refs.hierarchiesPivotTableBaseComponent.isUpdateFinished()
      this.$refs.hierarchiesPivotTableBaseComponent.undoUpdate(fullInstance.id)
    }
  }

  doRefreshUpdatedCells (patchResponse) {
    let modifiedCell = Object.assign({}, patchResponse.data)
    delete modifiedCell.updated_lines
    let updatedCells = [ modifiedCell, ...patchResponse.data.updated_lines ]
    this.$refs.hierarchiesPivotTableBaseComponent.refreshUpdatedCells(updatedCells)
  }

  hideTableAction (value) {
    this.hideTable = value
  }
}

export default ForecastingByProduct
