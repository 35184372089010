import { render, staticRenderFns } from "./ForecastingByProduct.html?vue&type=template&id=52718a6e&scoped=true&"
import script from "./ForecastingByProduct.component.js?vue&type=script&lang=js&"
export * from "./ForecastingByProduct.component.js?vue&type=script&lang=js&"
import style0 from "./ForecastingByProduct.scss?vue&type=style&index=0&id=52718a6e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52718a6e",
  null
  
)

export default component.exports