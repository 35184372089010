import Vue from 'vue'
import Component from 'vue-class-component'
import { validationMixin } from '@/apps/core/mixins/validations'
import { createNotification, createErrorNotification } from '@/lib/unnotificationsqueue'
import _ from 'lodash'

@Component({
  mixins: [
    validationMixin
  ],
  props: {}
})

class ForecastingByProductFilters extends Vue {
  forecastItems = []
  brandItems = []
  skuItems = []
  selectedForecast = null
  selectedBrand = null
  selectedSKU = null

  loading = false
  isLoadingForecast = false
  isLoadingBrands = false
  isLoadingSKUs = false
  noActiveForecastTable = true

  disabledForecastSelector = true
  disabledBrandSelector = true
  disabledSKUSelector = true
  showForecastBtnDisabled = true

  async mounted () {
    await this.getForecasts()
  }

  get isShowBtnDisabled () {
    if (this.selectedForecast && this.selectedBrand && this.selectedSKU && this.selectedSKU !== '') {
      return false
    } else {
      return true
    }
  }

  async getForecasts () {
    this.disabledForecastSelector = true
    this.disabledSKUSelector = true
    this.disabledBrandSelector = true
    this.isLoadingForecast = true
    this.forecastItems = []
    try {
      let tmpList = await this.$store.dispatch('forecasting/getForecastList', 'product')
      tmpList.forEach(elem => {
        elem['customName'] = `${elem.name} - ${elem.status || 'N/A'}`
        this.forecastItems.push(elem)
      })
      await this.setFirstForecast()
    } catch (err) {
      console.log(err)
    } finally {
      this.disabledForecastSelector = false
      this.disabledBrandSelector = false
      this.isLoadingForecast = false
    }
  }

  async setFirstForecast () {
    if (this.selectedForecast === null && this.forecastItems.length !== 0) {
      this.selectedForecast = this.forecastItems[0]
      await this.getForecastBrandList()
    }
  }

  async getForecastBrandList () {
    this.disabledBrandSelector = true
    this.disabledSKUSelector = true
    this.isLoadingBrands = true
    this.selectedBrand = null
    this.selectedSKU = null
    this.brandItems = []
    this.skuItems = []
    try {
      let tmpList = await this.$store.dispatch('forecasting/getForecastBrands', this.selectedForecast.id)
      tmpList.forEach(elem => {
        elem['customName'] = elem.name
        this.brandItems.push(elem)
      })
      this.brandItems = _.sortBy(this.brandItems, 'name')
      this.disabledBrandSelector = false
    } catch (e) {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.brandListError')))
    }
    this.isLoadingBrands = false
  }

  async getForecastSKUList () {
    this.disabledSKUSelector = true
    this.isLoadingSKUs = true
    this.selectedSKU = null
    this.skuItems = []
    try {
      let tmpList = await this.$store.dispatch('forecasting/getForecastSku', [this.selectedForecast.id, this.selectedBrand.id])
      tmpList.forEach(elem => {
        elem['customName'] = `${elem.name}`
        this.skuItems.push(elem)
      })
      if (tmpList.length === 0) {
        this.$store.dispatch('addNotification', createNotification(this.$t('forecasting.SKUListWarning'), 'warning'))
      } else {
        this.disabledSKUSelector = false
      }
    } catch (e) {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.SKUListError')))
    }
    this.isLoadingSKUs = false
  }

  async selectedNewForecast () {
    this.initializeForecastVariables()
    await this.getForecastBrandList()
  }

  async selectedNewBrand () {
    await this.getForecastSKUList()
  }

  initializeForecastVariables () {
    this.$emit('hideTable', true)
    this.noActiveForecastTable = true
    this.customerItems = []
    this.selectedBrand = null
    this.selectedSKU = null
    this.isLoadingSKUs = false
    this.disabledSKUSelectorSelector = true
  }

  onShowForecast () {
    const forecastData = {
      forecast: this.selectedForecast,
      brand: this.selectedBrand,
      sku: this.selectedSKU
    }
    this.$store.dispatch('forecasting/setForecastDataValues', forecastData)
    this.noActiveForecastTable = false
  }
}

export default ForecastingByProductFilters
